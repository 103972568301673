import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import $ from 'jquery/dist/jquery.js'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import OwlCarousel from 'react-owl-carousel2'
import { Tabs, Tab, Nav, Row, Col } from 'react-bootstrap'

import Layout from '../components/layout'
import Content from '../../content/main.yaml'

import sectionPic from '../assets/img/550x500.jpg'
import mainPic from '../assets/img/home-banner-1.svg'
import d2i from '../assets/img/logo/d2i.jpg'
import postxl from '../assets/img/logo/logo-postxl-black.png'
import molnify from '../assets/img/logo/molnify.png'
import Dropzone from '../components/Dropzone'
import ExportPlayground from '../components/ExportPlayground'
import StackBlitzExample from '../components/StackBlitzExample'
import tableGif from '../assets/img/createTable.gif'
import namedRangesGif from '../assets/img/namedRanges.gif'
import { Lightbox } from 'react-modal-image'

class Homepage extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      popup: null,
    }
  }
  closePopup() {
    this.setState({
      popup: null,
    })
  }
  openPopup(href) {
    this.setState({
      popup: href,
    })
  }
  render() {
    return (
      <Layout>
        <Helmet title={Content.title} />
        {this.state.popup && (
          <Lightbox
            medium={this.state.popup}
            onClose={this.closePopup.bind(this)}
            hideDownload={true}
          ></Lightbox>
        )}
        <section id="home" className="effect-section gray-bg">
          <div className="effect-shape theme-bg"></div>
          <div className="container container-large">
            <div className="row full-screen sm-full-screen-auto align-items-center justify-content-center">
              <div className="col-12 col-lg-5">
                <div className="md-p-100px-t md-m-40px-t sm-m-20px-t md-p-30px-b sm-p-50px-b">
                  <h1 className="white-color font-w-600 font-44px md-font-38px sm-font-28px m-15px-b">
                    {Content.header}
                  </h1>
                  <h2 className="white-color">{Content.tagline}</h2>
                  <div className="btn-bar p-25px-t">
                    <button
                      className="m-btn m-btn-theme2nd m-btn-radius"
                      onClick={() => goTo('playground')}
                    >
                      {Content.ctaPrimary.text}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <OwlCarousel
                  className="owl-carousel white-dot"
                  options={{
                    items: 1,
                    dots: true,
                    loop: true,
                    center: true,
                    margin: 30,
                  }}
                >
                  <div class="white-bg p-40px-tb p-30px-lr m-15px-b border-radius-5 box-shadow">
                    <div class="f-icon-80px icon font-30px theme-color gray-bg text-center border-radius-50 m-25px-b">
                      <i class="icon-tools-2"></i>
                      <span class="dots">
                        <i class="dot dot1"></i>
                        <i class="dot dot2"></i>
                        <i class="dot dot3"></i>
                      </span>
                    </div>

                    <p className="mb-0 text-uppercase">Step 1</p>
                    <h5 class="theme-color font-22px font-w-600 m-20px-b">
                      Prepare template
                    </h5>
                    <p class="m-0px">
                      Tag cells with names and data as Excel tables.
                    </p>
                    <div class="btn-bar m-30px-t m-btn-dual">
                      <button
                        class="m-btn m-btn-t-theme m-btn-radius"
                        onClick={this.openPopup.bind(this, namedRangesGif)}
                      >
                        {/* <i className="fab fa-play m-5px-r"></i> */}
                        View cell example
                      </button>
                      <button
                        class="magnific-link m-btn m-btn-t-theme m-btn-radius"
                        onClick={this.openPopup.bind(this, tableGif)}
                      >
                        View table example
                      </button>
                    </div>
                  </div>
                  <div class="white-bg p-40px-tb p-30px-lr m-15px-b border-radius-5 box-shadow">
                    <div class="f-icon-80px icon font-30px theme-color gray-bg text-center border-radius-50 m-25px-b">
                      <i class="icon-mobile"></i>
                      <span class="dots">
                        <i class="dot dot1"></i>
                        <i class="dot dot2"></i>
                        <i class="dot dot3"></i>
                      </span>
                    </div>

                    <p className="mb-0 text-uppercase">Step 2</p>
                    <h5 class="theme-color font-22px font-w-600 m-20px-b">
                      Call xlPort API
                    </h5>
                    <SyntaxHighlighter
                      className="main-snippet wrap-code"
                      style={{ background: monokai.hljs.background }}
                      language="bash"
                      style={monokai}
                    >
                      {Content.mainCodeSnippet}
                    </SyntaxHighlighter>
                  </div>
                </OwlCarousel>
                {/* <div className="feature-box hover-top box-shadow transition white-bg border-radius-5 m-30px-b">
                  <div className="feature-content">
                    <h5>1. Prepare template</h5>
                    <div className="row">
                      <div className="col-lg-6">
                        <p>Tag cells with names</p>
                      </div>
                      <div className="col-lg-6">
                        <p>Name data tables</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="feature-box hover-top box-shadow transition white-bg border-radius-5 m-30px-b">
                  <div className="feature-content">
                    <h5>2. Call API</h5>
                    <p>Use cURL or our SDKs</p>
                  </div>
                </div> */}
                {/* <img className="max-width-auto" src={mainPic} /> */}
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="section gray-bg">
          <div className="container">
            <div className="row justify-content-center sm-m-25px-b m-45px-b">
              <div className="col-lg-8 col-md-10">
                <div className="section-title text-center">
                  <h2 className="dark-color font-w-600 m-20px-b p-20px-b theme-after after-50px font-40px md-font-38px sm-font-28px">
                    {Content.featuresHeadline}
                  </h2>
                  <p className="font-16px m-0px">
                    {Content.featuresDescription}
                  </p>
                </div>
              </div>
            </div>
            <div className="row items-stretch">
              {Content.features.map(feature => (
                <div className="col-md-6 col-lg-4 m-15px-tb flex-auto">
                  <div className="h-100 feature-box-ef hover-rotate white-bg box-shadow-hover transition border-radius-5 hover-top">
                    <div className="icon ef-2 theme-bg theme2nd-color hr-rotate-after border-radius-50">
                      <i className={'white-color ' + feature.icon}></i>
                    </div>
                    <div className="feature-content">
                      <h5 className="dark-color">{feature.headline}</h5>
                      <ul className="list-type-02 text-left mt-4">
                        {feature.entries.map(entry => {
                          return (
                            <li>
                              <i className="fas fa-check"></i>
                              {entry.name}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="playground" className="section">
          <div className="container">
            <div className="row justify-content-center sm-m-25px-b m-45px-b">
              <div className="col-lg-8 col-md-10">
                <div className="section-title text-center">
                  <h2 className="dark-color font-w-600 m-20px-b p-20px-b theme-after after-50px font-40px md-font-38px sm-font-28px">
                    Playground
                  </h2>
                  {/* <p className="font-16px m-0px">
                    {Content.featuresDescription}
                  </p> */}
                </div>
              </div>
            </div>
            <div className="tab-style-3">
              <Tab.Container defaultActiveKey="import">
                <Nav className="nav-justified box-shadow" variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="import">
                      <span>Import</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="export">
                      <span>Export</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="example">
                      <span>Example App</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="import">
                    <Row>
                      <Col lg={6}>
                        <p>{Content.playground.import.description}</p>
                      </Col>
                    </Row>
                    <Dropzone></Dropzone>
                  </Tab.Pane>
                  <Tab.Pane eventKey="export">
                    <Row>
                      <Col lg={6}>
                        <p>{Content.playground.export.description}</p>
                      </Col>
                    </Row>

                    <ExportPlayground
                      templates={Content.playground.export.templates}
                    ></ExportPlayground>
                    {/* <select>
                      {Content.playground.export.templates.map(template => {
                        return (
                          <option value={template.id}>{template.name}</option>
                        )
                      })}
                    </select> */}
                  </Tab.Pane>
                  <Tab.Pane eventKey="example">
                    <p className="mb-4">
                      {/* {Content.playground.complex.description} */}
                      This is a fork of the{' '}
                      <a
                        href="http://todomvc.com/examples/react/#/"
                        target="_blank"
                      >
                        classic TodoMVC project
                      </a>{' '}
                      with xlPort integration. Check out the file footer.js!
                    </p>

                    <StackBlitzExample></StackBlitzExample>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
            {/* <div className="btn-bar p-25px-t">
              <a
                className="m-btn m-btn-theme2nd m-btn-radius"
                href={Content.ctaSecondary.href}
              >
                {Content.ctaSecondary.text}
              </a>
            </div> */}
          </div>
        </section>

        <section id="pricing" className="section gray-bg">
          <div className="container">
            <div className="row justify-content-center sm-m-25px-b m-45px-b">
              <div className="col-lg-8 col-md-10">
                <div className="section-title text-center">
                  <h2 className="dark-color font-w-600 m-20px-b p-20px-b theme-after after-50px font-40px md-font-38px sm-font-28px">
                    Pricing
                  </h2>
                </div>
              </div>
            </div>
            <div className="row items-stretch">
              {Content.pricing.map((tier, tierIndex) => {
                return (
                  <div
                    className="flex-auto col-lg-3 col-md-6 m-15px-tb"
                    key={'tier' + tierIndex}
                  >
                    <div
                      className="h-100 price-table d-flex flex-column"
                      style={{ position: 'relative' }}
                    >
                      {tier.special && (
                        <div className="msg-container">
                          <span className="msg">{tier.special}</span>
                        </div>
                      )}
                      <div className="pt-head">
                        <h5>{tier.name}</h5>
                      </div>
                      <div className="pt-body flex-grow-1">
                        {(typeof tier.cost !== 'number' || tier.cost > 0) && (
                          <h4>
                            {typeof tier.cost === 'number' &&
                              tier.cost > 0 &&
                              `${Content.pricingCurrency}`}
                            {tier.cost}
                            {typeof tier.cost === 'number' && tier.cost > 0 && (
                              <span>/month</span>
                            )}
                          </h4>
                        )}
                        <ul className="list-type-02">
                          {tier.limit ? (
                            <li>
                              <i className="fas fa-check"></i> Up to{' '}
                              {tier.limit} calls per day
                            </li>
                          ) : (
                            <li>
                              <i className="fas fa-check"></i> Unlimited calls
                            </li>
                          )}
                          {tier.features.map((feature, index) => {
                            return (
                              <li key={`tier${tierIndex}feature${index}`}>
                                <i className="fas fa-check"></i>
                                {feature}
                              </li>
                            )
                          })}
                        </ul>
                      </div>
                      <div className="pt-footer">
                        <a
                          href={tier.action.href}
                          className="m-btn m-btn-theme2nd m-btn-radius d-block"
                        >
                          {tier.action.name}
                        </a>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="section gray-bg">
          <div className="row justify-content-center sm-m-25px-b m-45px-b">
            <div className="col-lg-8 col-md-10">
              <div className="section-title text-center">
                <h2 className="dark-color font-w-600 m-20px-b p-20px-b theme-after after-50px font-40px md-font-38px sm-font-28px">
                  Customers
                </h2>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="brand-minus box-shadow-lg">
              <OwlCarousel
                options={{ items: 3, dots: false, loop: true, center: true }}
              >
                <a
                  class="brand-item"
                  href="https://www.data2impact.com/en/"
                  target="_blank"
                >
                  <img src={d2i} title="data2impact GmbH" alt="" />
                </a>
                <a class="brand-item" href="https://postxl.com" target="_blank">
                  <img src={postxl} title="PostXL GmbH" alt="" />
                </a>
                <a
                  class="brand-item"
                  href="https://www.molnify.com/"
                  target="_blank"
                >
                  <img src={molnify} title="Molnify" alt="" />
                </a>
              </OwlCarousel>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
  componentDidMount() {
    const fixedHeaderHandler = function() {
      var HscrollTop = $(window).scrollTop()
      if (HscrollTop >= 100) {
        $('header').addClass('fixed-header')
      } else {
        $('header').removeClass('fixed-header')
      }
    }

    fixedHeaderHandler()
    $(document).on('scroll', fixedHeaderHandler)
  }
}

function goTo(id) {
  const el = document.getElementById(id)
  if (el) {
    el.scrollIntoView({ behavior: 'smooth' })
  }
}

export default Homepage
