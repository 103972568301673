import React, { useState } from 'react'
import {
  Tab,
  Tabs,
  Row,
  Col,
  ListGroup,
  Table,
  Form,
  Pagination,
  Nav,
} from 'react-bootstrap'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs'
// import '../assets/plugin/bootstrap3/css/bootstrap.min.css'
import Select from 'react-select'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import cars from '../../content/data/cars.json'
import saveAs from 'file-saver'
import { exportUrl } from '../url'

export default function ExportPlayground(props) {
  const emptyUser = { firstName: '', lastName: '', department: 'HR' }
  const [codeA, setCodeA] = useState({ Fruit: '' })
  const [codeB, setCodeB] = useState({
    users: [emptyUser],
  })
  const [codeC, setCodeC] = useState(cars)
  const [page, setPage] = useState(0)

  const defaultOption = { value: 'a', label: 'Basic Template' }

  const selectOptions = [
    defaultOption,
    { value: 'b', label: 'Tabular Data Template' },
    { value: 'c', label: 'Advanced Template' },
  ]

  const [activeKey, setActiveKey] = useState(defaultOption)

  const departments = [
    { value: 'accounting', label: 'Accounting' },
    { value: 'hr', label: 'HR' },
    { value: 'sales', label: 'Sales' },
  ]

  const submit = (templateId, data) => {
    return fetch(exportUrl, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Authorization: 'xlport apikey pass123',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        templateId,
        data,
      }),
    })
      .then(response => {
        if (response.headers.get('content-type') === 'application/json') {
          return response
            .json()
            .then(json => Promise.reject(new Error(json.message)))
        } else {
          return response.blob()
        }
      })
      .then(blob => saveAs(blob, 'result.xlsx'))
  }

  const updateFruit = value => {
    setCodeA({ ...codeA, Fruit: value })
  }

  const addRow = () => {
    setCodeB({ ...codeB, users: [...codeB.users, emptyUser] })
  }

  const updateUser = (index, user) => {
    setCodeB({
      ...codeB,
      users: codeB.users.map((u, i) => (i === index ? user : u)),
    })
  }

  const itemsPerPage = 4
  const paginationItems = [
    <Pagination.Prev disabled={page === 0} onClick={() => setPage(page - 1)} />,
    <Pagination.Next
      disabled={page * itemsPerPage >= codeC.length}
      onClick={() => setPage(page + 1)}
    />,
  ]

  return (
    <div>
      <Row>
        <Col lg={3}>
          <p className="font-14px font-w-500 m-5px-b">Select template</p>
          <Select
            options={selectOptions}
            onChange={option => setActiveKey(option)}
            placeholder="Select template..."
            value={activeKey}
          ></Select>
        </Col>
        <Col lg={9}>
          {activeKey.value === 'a' ? (
            <div>
              <p className="font-14px font-w-500 m-5px-b">
                Fill in template data
              </p>
              <Form onSubmit={event => event.preventDefault()}>
                <Row>
                  <Col lg={6}>
                    <Form.Group>
                      <Form.Control
                        type="text"
                        value={codeA.Fruit}
                        onInput={event => updateFruit(event.target.value)}
                      />
                      <Form.Label>Your favorite fruit</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg={6}></Col>
                </Row>
                <Submit
                  onClick={() => submit('BasicPlaygroundTemplate.xlsx', codeA)}
                />
              </Form>
            </div>
          ) : activeKey.value === 'b' ? (
            <div>
              <p className="font-14px font-w-500 m-5px-b">
                Fill in template data
              </p>
              <Table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>First name</th>
                    <th>Last name</th>
                    <th>Department</th>
                  </tr>
                </thead>
                <tbody>
                  {codeB.users.map((user, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            type="text"
                            value={user.firstName}
                            placeholder="First name"
                            className="form-control"
                            onInput={event =>
                              updateUser(index, {
                                ...user,
                                firstName: event.target.value,
                              })
                            }
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            value={user.lastName}
                            placeholder="Last name"
                            className="form-control"
                            onInput={event =>
                              updateUser(index, {
                                ...user,
                                lastName: event.target.value,
                              })
                            }
                          />
                        </td>
                        <td>
                          <Select
                            options={departments}
                            value={user.department}
                            className="mw-200"
                            onChange={option =>
                              updateUser(index, { ...user, department: option })
                            }
                          ></Select>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              <button
                type="button"
                className="m-btn m-btn-sm m-btn-radius"
                onClick={() => addRow()}
              >
                Add row
              </button>
              <Submit
                onClick={() =>
                  submit(
                    'TabularDataPlaygroundTemplate.xlsx',
                    processTabularTemplate(codeB)
                  )
                }
              />
            </div>
          ) : activeKey.value === 'c' ? (
            <div>
              <p>
                Data set taken from{' '}
                <a href="https://raw.githubusercontent.com/vega/vega-datasets/master/data/cars.json">
                  https://raw.githubusercontent.com/vega/vega-datasets/master/data/cars.json
                </a>
              </p>
              <Table responsive className="mh-400">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>
                      Miles <br />
                      per Gallon
                    </th>
                    <th>Cylinders</th>
                    <th>
                      Displace
                      <wbr />
                      ment
                    </th>
                    <th>Horsepower</th>
                    <th>Weight, lbs.</th>
                    <th>Acceleration</th>
                    <th>Year</th>
                    <th>Origin</th>
                  </tr>
                </thead>
                <tbody>
                  {codeC
                    .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
                    .map(row => {
                      return (
                        <tr>
                          <td>{row.Name}</td>
                          <td>{row.Miles_per_Gallon}</td>
                          <td>{row.Cylinders}</td>
                          <td>{row.Displacement}</td>
                          <td>{row.Horsepower}</td>
                          <td>{row.Weight_in_lbs}</td>
                          <td>{row.Acceleration}</td>
                          <td>{row.Year}</td>
                          <td>{row.Origin}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </Table>
              <Row className="mt-4 justify-content-space-between">
                <Col>
                  <Pagination>{paginationItems}</Pagination>
                </Col>
                <Col>
                  <Submit
                    onClick={() =>
                      submit(
                        'AdvancedPlaygroundTemplate.xlsx',
                        processCars(codeC)
                      )
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <div></div>
          )}
        </Col>
      </Row>
      {activeKey.value === 'a' && codeA ? (
        <div>
          <Preview
            curl={exportCurl('BasicPlaygroundTemplate.xlsx', codeA)}
            json={JSON.stringify(codeA, null, 4)}
          />
          {/*  */}
        </div>
      ) : activeKey.value === 'b' && codeB ? (
        <div>
          <Preview
            curl={exportCurl(
              'TabularDataPlaygroundTemplate.xlsx',
              processTabularTemplate(codeB)
            )}
            json={JSON.stringify(processTabularTemplate(codeB), null, 4)}
          />
          {/*  */}
        </div>
      ) : activeKey.value === 'c' && codeC ? (
        <div>
          <Preview
            curl={exportCurl(
              'AdvancedPlaygroundTemplate.xlsx',
              processCars(codeC)
            )}
            json={JSON.stringify(processCars(codeC), null, 4)}
          />
          {/*  */}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  )
}

function Preview(props) {
  const [copyText, setText] = useState('Copy to clipboard')
  return (
    <div className="tab-style-4">
      <Tab.Container defaultActiveKey="curl">
        <Nav variant="fill">
          <Nav.Item>
            <Nav.Link eventKey="curl">cURL command</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="json">JSON preview</Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="curl">
            {props.curl && (
              <div>
                <div
                  className="position-relative p-4 border-radius-10 mh-250"
                  style={{ background: monokai.hljs.background }}
                >
                  <SyntaxHighlighter
                    language="bash"
                    style={monokai}
                    className="wrap-code white-color"
                    customStyle={{
                      background: 'none',
                      height: '100%',
                      overflowY: 'auto',
                      padding: 0,
                    }}
                  >
                    {props.curl}
                  </SyntaxHighlighter>
                  <CopyToClipboard text={props.curl}>
                    <button
                      class="m-btn m-btn-xsm m-btn-radius m-btn-white to-dark box-shadow"
                      onClick={() => {
                        setText('Copied!')
                        setTimeout(() => {
                          setText('Copy to clipboard')
                        }, 1500)
                      }}
                      style={{
                        position: 'sticky',
                        bottom: '0',
                        zIndex: 2,
                        float: 'right',
                      }}
                    >
                      {copyText}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </Tab.Pane>
          <Tab.Pane eventKey="json">
            {props.json && (
              <div>
                <div
                  className="position-relative p-4 border-radius-10 mh-250"
                  style={{ background: monokai.hljs.background }}
                >
                  <SyntaxHighlighter
                    language="javascript"
                    style={monokai}
                    className="white-color"
                    customStyle={{
                      background: 'none',
                      height: '100%',
                      overflowY: 'auto',
                      padding: 0,
                    }}
                  >
                    {props.json}
                  </SyntaxHighlighter>
                  <CopyToClipboard text={props.json}>
                    <button
                      class="m-btn m-btn-xsm m-btn-radius m-btn-white to-dark box-shadow"
                      onClick={() => {
                        setText('Copied!')
                        setTimeout(() => {
                          setText('Copy to clipboard')
                        }, 1500)
                      }}
                      style={{
                        position: 'sticky',
                        bottom: '0',
                        zIndex: 2,
                        float: 'right',
                      }}
                    >
                      {copyText}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  )
}

function Submit(props) {
  return (
    <div className="btn-bar text-right">
      <p className="font-14px font-w-500 m-5px-b">&nbsp;</p>
      <button
        type="submit"
        className="m-btn m-btn-radius m-btn-theme"
        onClick={props.onClick}
      >
        Download as Excel
      </button>
    </div>
  )
}

function exportCurl(templateId, data) {
  return `curl -X PUT ${exportUrl} -d "{\"templateId\":\"${templateId}\", \"data\":${JSON.stringify(
    data
  ).replace(
    /"/g,
    '\\"'
  )}}" --header "Content-Type: application/json" --header "Authorization: xlport apikey pass123" -O -J`
}

function processTabularTemplate(formData) {
  return {
    Users: formData.users.map((user, index) => ({
      '#': index + 1,
      'First name': user.firstName,
      'Last name': user.lastName,
      Department: user.department ? user.department.label : '',
    })),
  }
}

function processCars(cars) {
  return {
    Cars: cars.map(car => ({
      ...car,
      Year: car.Year && +car.Year.replace('-01-01', ''),
    })),
  }
}
