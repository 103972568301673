import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { importUrl } from '../url'

export default function Dropzone() {
  const [json, setJson] = useState(null)
  const onDrop = useCallback(acceptedFiles => {
    if (!acceptedFiles.length) return

    const formData = new FormData()
    const request = JSON.stringify({ properties: ['*'], tables: ['*'] })
    formData.append('request', request)
    formData.append('file', acceptedFiles[0])
    fetch(importUrl, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        Authorization: 'xlport apikey pass123',
      },
      body: formData,
      json: true,
    })
      .then(res => res.json())
      .then(setJson)
  }, [])
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    accept: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel.sheet.macroEnabled.12',
      'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    ],
  })

  return (
    <div
      {...getRootProps({
        className: `p-4 dropzone box-shadow ${
          isDragActive
            ? 'hovering'
            : json
            ? returnedEmpty(json)
              ? 'error'
              : 'json'
            : ''
        }`,
      })}
    >
      <input {...getInputProps()} />
      {json ? (
        returnedEmpty(json) ? (
          <div className="position-relative">
            <p>
              We couldn't find any data in the provided file. xlPort API only
              knows about data that was given a name (either a named range or a
              named table). Check out{' '}
              <a
                onClick={event => {
                  event.stopPropagation()
                  event.preventDefault()
                  const el = document.getElementById('home')
                  if (el) {
                    el.scrollIntoView({ behavior: 'smooth' })
                  }
                }}
                href="#"
              >
                these examples
              </a>{' '}
              to give you a better idea!
            </p>
            <div className="btn-bar text-right">
              <button
                type="reset"
                className="m-btn m-btn-sm m-btn-t-theme m-btn-radius"
                onClick={event => {
                  event.stopPropagation()
                  setJson(null)
                }}
              >
                Retry
              </button>
            </div>
          </div>
        ) : (
          <div className="position-relative">
            <pre>
              <code>{JSON.stringify(json, null, 4)}</code>
            </pre>
            <div className="btn-bar text-right">
              <button
                type="reset"
                className="m-btn m-btn-sm m-btn-white m-btn-radius m-btn-bottom-corner"
                onClick={event => {
                  event.stopPropagation()
                  setJson(null)
                }}
              >
                Reset
              </button>
            </div>
          </div>
        )
      ) : (
        <div>
          <div className="icon box-shadow">
            <i className="icon-download"></i>
            <span className="dots">
              <i className="dot dot1"></i>
              <i className="dot dot2"></i>
              <i className="dot dot3"></i>
            </span>
          </div>
          {isDragActive ? (
            <p className="m-0">Drop the files here ...</p>
          ) : (
            <p className="m-0">
              Drop any Excel file here to retrieve its contents as JSON
            </p>
          )}
        </div>
      )}
    </div>
  )
}

function returnedEmpty(response) {
  return (
    response.status === 'success' &&
    Object.values(response.data.tables).length === 0 &&
    Object.values(response.data.properties).length === 0
  )
}
