import React, { useState } from 'react'
import sdk from '@stackblitz/sdk'
import App from '!!raw-loader!../stackblitz-example/src/App'
import Footer from '!!raw-loader!../stackblitz-example/src/Footer'
import Header from '!!raw-loader!../stackblitz-example/src/Header'
import indexHtml from '!!raw-loader!../stackblitz-example/public/index.html'
import MainSection from '!!raw-loader!../stackblitz-example/src/MainSection'
import TodoItem from '!!raw-loader!../stackblitz-example/src/TodoItem'
import TodoTextInput from '!!raw-loader!../stackblitz-example/src/TodoTextInput'
import indexJs from '!!raw-loader!../stackblitz-example/src/index'
import styleCss from '!!raw-loader!../stackblitz-example/src/style.css'

class StackBlitzExample extends React.Component {
  componentDidMount() {
    sdk.embedProject(
      'stackblitz',
      {
        files: {
          'src/App.js': App,
          'src/Footer.js': Footer,
          'src/Header.js': Header,
          'public/index.html': indexHtml,
          'src/MainSection.js': MainSection,
          'src/TodoItem.js': TodoItem,
          'src/TodoTextInput.js': TodoTextInput,
          'src/index.js': indexJs,
          'src/style.css': styleCss,
        },
        template: 'create-react-app',
        title: 'TodoMVC with xlPort integration',
        description:
          'A classic React-based example with additional fuctionality to export data to an Excel file',
        dependencies: {
          classnames: '2.2.6',
          'file-saver': '2.0.2',
          'prop-types': '15.7.2',
          react: '16.9.0',
          'react-dom': '16.9.0',
          serve: '11.2.0',
          'todomvc-app-css': '2.2.0',
          'todomvc-common': '1.0.5',
        },
      },
      { height: 600, forceEmbedLayout: true, view: 'preview' }
    )
  }
  

  render() {
    return <div id="stackblitz"></div>
  }
}

export default StackBlitzExample
